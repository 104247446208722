import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import scriptLoadingIssue from '../images/script-loading-issue.gif';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`HTMX is a lightweight (I believe 14K) JS library that facilitates `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/HATEOAS"
      }}>{`HATEOS`}</a>{`. This library has no dependencies 🤯`}</p>
    <p>{`After building a notes/reminder application with Astro and HTMX I have some takeaways.`}</p>
    <h2><HashLink id="observations" to="/htmx-impressions#observations" mdxType="HashLink">{`Observations`}</HashLink></h2>
    <p>{`Some fairly simple features that can be built in short order by a library like React are not trivial with HTMX.`}</p>
    <p>{`For example, it's not as simple as you may think to replace DOM elements back and forth when the replacement relies on JavaScript. In the GIF below you can see that I cannot click the "..." button again after canceling the form.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={scriptLoadingIssue} alt="Six images of 4 strands gradually becoming more intertwined" width="60%" height="100%" />
    </div>
    <p>{`This could be user error, there are probably techniques I don't know about. This is also partly an Astro integration issue but I think you'd come across similar limitations with other frameworks.`}</p>
    <p>{`To fix this type of issue, I use a `}<inlineCode parentName="p">{`MutationObserver`}</inlineCode>{` to watch the parent element for DOM changes. When the page updates I re-register my event listeners.`}</p>
    <p>{`Other observations:`}</p>
    <ul>
      <li parentName="ul">{`XSS considerations if your backend framework doesn't offer XSS protection/sanitization`}</li>
      <li parentName="ul">{`Scenario: you want to update multiple areas of the page as a result of user interaction`}
        <ul parentName="li">
          <li parentName="ul">{`swap the closest common parent`}
            <ul parentName="li">
              <li parentName="ul">{`pros: simplest`}</li>
              <li parentName="ul">{`cons: performance isn't great for large parent elements`}</li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://htmx.org/attributes/hx-swap-oob/"
            }}>{`out of band (OOB) update`}</a>
            <ul parentName="li">
              <li parentName="ul">{`pros: one network request`}</li>
              <li parentName="ul">{`cons: a bit more complex`}</li>
            </ul>
          </li>
          <li parentName="ul">{`trigger an event and have consumers listen`}
            <ul parentName="li">
              <li parentName="ul">{`pros: opt-in to the event`}</li>
              <li parentName="ul">{`cons:`}
                <ul parentName="li">
                  <li parentName="ul">{`a bit more complex`}</li>
                  <li parentName="ul">{`more requests over the wire`}</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Contrary to popular belief, I think you `}<em parentName="li">{`could`}</em>{` use HTMX for large projects`}
        <ul parentName="li">
          <li parentName="ul">{`you'd need to establish patterns and have a team of disciplined developers`}</li>
          <li parentName="ul">{`over time you could build tooling to automatically enforce patterns`}</li>
        </ul>
      </li>
    </ul>
    <h2><HashLink id="pros" to="/htmx-impressions#pros" mdxType="HashLink">{`Pros`}</HashLink></h2>
    <ul>
      <li parentName="ul">{`The juice is worth the squeeze having most of the state living on the server.`}
        <ul parentName="li">
          <li parentName="ul">{`I could probably write a whole post on this. I guess I didn't realize how complex synchronizing client and server state is until I didn't have to anymore.`}</li>
          <li parentName="ul">{`The only state on the client is purely presentational (e.g. expanding/collapsing accordions).`}</li>
        </ul>
      </li>
      <li parentName="ul">{`"Lightweight" is an understatement when comparing to a React app.`}
        <ul parentName="li">
          <li parentName="ul">{`Removing the time spent downloading a relatively massive JS bundle is very noticeable.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`For the most part, HTMX works well with Astro, especially after `}<a parentName="li" {...{
          "href": "https://docs.astro.build/en/basics/astro-pages/#page-partials"
        }}>{`page partials`}</a>{` were introduced.`}</li>
      <li parentName="ul">{`HTMX `}<a parentName="li" {...{
          "href": "https://htmx.org/examples/lazy-load/"
        }}>{`lazy loading support`}</a>{` is simple and powerful.`}</li>
    </ul>
    <h2><HashLink id="cons" to="/htmx-impressions#cons" mdxType="HashLink">{`Cons`}</HashLink></h2>
    <ul>
      <li parentName="ul">{`Lots of chatty network calls.`}
        <ul parentName="li">
          <li parentName="ul">{`This is not great for offline-first apps, but what is?`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Accessibility doesn't seem to be the highest priority.`}
        <ul parentName="li">
          <li parentName="ul">{`Could be an issue that a `}<inlineCode parentName="li">{`div`}</inlineCode>{` can make a network call when clicked.`}</li>
          <li parentName="ul">{`E.g. `}<a parentName="li" {...{
              "href": "https://htmx.org/examples/modal-custom/"
            }}>{`custom modal in docs`}</a>{` `}</li>
        </ul>
      </li>
    </ul>
    <h2><HashLink id="takeaways" to="/htmx-impressions#takeaways" mdxType="HashLink">{`Overall thoughts`}</HashLink></h2>
    <p>{`After using "modern" client technologies for almost a decade it's definitely a shock to the system learning HTMX. I found I've needed to fundamentally change the way I think about building my client and API layers.`}</p>
    <p>{`Development feels very UI first vs API -> client. API design kind of feels like GraphQL schema design in that sense. Only give me what I need as the client. If what I need changes, the schema changes.`}</p>
    <p>{`In this age, the pendulum is swinging back to simplicity - made possible by browsers adding native support for more and more.`}</p>
    <p>{`My application feels very snappy. It's extremely nice not to have so much JS bloat.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      